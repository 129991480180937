@charset "utf-8";

/* CSS Document */
/*
html5doctor.com Reset Stylesheet
v1.6.1
Last Updated: 2010-09-17
Author: Richard Clark - http://richclarkdesign.com
Twitter: @rich_clark
*/
html,
body,
div,
span,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
abbr,
address,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
samp,
small,
strong,
sub,
sup,
var,
b,
i,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
summary,
time,
mark,
audio,
video {
 margin: 0;
 padding: 0;
 border: 0;
 outline: 0;
 font-size: 100%;
 vertical-align: baseline;
 background: transparent;
 -webkit-text-size-adjust: 100%;
 /*smartphone*/
}

html {
 font-size: 62.5%;
}

body {
 line-height: 1;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
 display: block;
}

blockquote,
q {
 quotes: none;
}

ul {
 list-style: none;
}

address,
dl,
dt,
dd {
 font-style: normal;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
 content: '';
 content: none;
}

a {
 margin: 0;
 padding: 0;
 font-size: 100%;
 vertical-align: baseline;
 background: transparent;
}

/* change colours to suit your needs */
ins {
 background-color: #ff9;
 color: #000;
 text-decoration: none;
}

/* change colours to suit your needs */
mark {
 background-color: #ff9;
 color: #000;
 font-style: italic;
 font-weight: bold;
}

del {
 text-decoration: line-through;
}

abbr[title],
dfn[title] {
 border-bottom: 1px dotted;
 cursor: help;
}

table {
 border-collapse: collapse;
 border-spacing: 0;
}

/* change border colour to suit your needs */
hr {
 display: block;
 height: 1px;
 border: 0;
 border-top: 1px solid #cccccc;
 margin: 1em 0;
 padding: 0;
}

input,
select {
 vertical-align: middle;
}

a img {
 border: none;
}

img {
 vertical-align: bottom
}

ol {
 padding-left: 2em;
}

#main section,
#main aside {
 margin-bottom: 20px;
}

article,
aside,
footer,
header,
nav,
section,
main {
 display: block;
}

* {
 box-sizing: border-box;
}

*:before,
*:after {
 box-sizing: inherit;
}

input,
button,
select,
textarea {
 -webkit-appearance: none;
 -moz-appearance: none;
 appearance: none;
 background: transparent;
 border: none;
 border-radius: 0;
 font: inherit;
 outline: none;
}

textarea {
 resize: vertical;
}

// input[type='checkbox'],
// input[type='radio'] {
//  display: none;
// }

input[type='submit'],
input[type='button'],
label,
button,
select {
 cursor: pointer;
}

select::-ms-expand {
 display: none;
}